var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c('VTabs',{staticClass:"tabs mt-5",attrs:{"vertical":"","slider-size":"6","color":"#f4c020"}},[_vm._l((_vm.tabs),function(tab){return _c('VTab',{key:tab.ref,ref:tab.ref,refInFor:true,attrs:{"disabled":tab.disabled}},[_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('VIcon',_vm._g(_vm._b({attrs:{"left":""}},'VIcon',attrs,false),on),[_vm._v(" "+_vm._s(tab.tag)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(`${tab.langKey}`)))])])],1)}),_vm._l((_vm.tabItems),function(tabItem){return _c('VTabItem',{key:tabItem},[(_vm.variablesByTab[tabItem].length !== 0)?_c('VCard',[_c('VCardText',{staticClass:"scrollable"},[_c('VContainer',[_c('CMTable',{attrs:{"dense":"","items":_vm.variablesByTab[tabItem],"filterable":false,"sorteable":false,"default-footer":false,"slots":_vm.slots,"height":250,"with-items-count":false,"with-actions":_vm.variablesByTab[tabItem].length !== 0,"row-height":50,"pagination":false,"header-config":{
                actions: { notFilterable: true },
                name: { notFilterable: true },
                plot: { notFilterable: true },
                draw: { notFilterable: true },
                expression: { notFilterable: true },
                heatmap: { notFilterable: true },
              },"custom-title":_vm.titleTranslations}}),_c('VBtn',{staticClass:"mt-4",attrs:{"color":"primary black--text"},on:{"click":function($event){return _vm.removeAllVariables(tabItem)}}},[_vm._v(" "+_vm._s(_vm.$t('delete_all'))+" ")]),(_vm.feedback.visible)?_c('VCol',{attrs:{"cols":"12"}},[(_vm.feedback.visible)?_c('VAlert',{attrs:{"dense":"","type":_vm.feedback.type,"elevation":"5","transition":"slide-y-transition"}},[_vm._v(" "+_vm._s(_vm.feedback.message)+" ")]):_vm._e()],1):_vm._e(),(_vm.findVariablePlotable(tabItem))?_c('MSMathExpressions',{attrs:{"variables":_vm.variablesByTab[tabItem]}}):_vm._e()],1)],1)],1):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }