<template>
  <div class="my-5">
    <VTabs
      class="tabs mt-5"
      vertical
      slider-size="6"
      color="#f4c020"
    >
      <VTab
        v-for="tab in tabs"
        :key="tab.ref"
        :ref="tab.ref"
        :disabled="tab.disabled"
      >
        <VTooltip right>
          <template #activator="{ on, attrs }">
            <VIcon
              left
              v-bind="attrs"
              v-on="on"
            >
              {{ tab.tag }}
            </VIcon>
          </template>
          <span>{{ $t(`${tab.langKey}`) }}</span>
        </VTooltip>
      </VTab>

      <VTabItem
        v-for="tabItem in tabItems"
        :key="tabItem"
      >
        <VCard v-if="variablesByTab[tabItem].length !== 0">
          <VCardText class="scrollable">
            <VContainer>
              <CMTable
                dense
                :items="variablesByTab[tabItem]"
                :filterable="false"
                :sorteable="false"
                :default-footer="false"
                :slots="slots"
                :height="250"
                :with-items-count="false"
                :with-actions="variablesByTab[tabItem].length !== 0"
                :row-height="50"
                :pagination="false"
                :header-config="{
                  actions: { notFilterable: true },
                  name: { notFilterable: true },
                  plot: { notFilterable: true },
                  draw: { notFilterable: true },
                  expression: { notFilterable: true },
                  heatmap: { notFilterable: true },
                }"
                :custom-title="titleTranslations"
              />

              <VBtn
                class="mt-4"
                color="primary black--text"
                @click="removeAllVariables(tabItem)"
              >
                {{ $t('delete_all') }}
              </VBtn>

              <VCol
                v-if="feedback.visible"
                cols="12"
              >
                <VAlert
                  v-if="feedback.visible"
                  dense
                  :type="feedback.type"
                  elevation="5"
                  transition="slide-y-transition"
                >
                  {{ feedback.message }}
                </VAlert>
              </VCol>

              <MSMathExpressions
                v-if="findVariablePlotable(tabItem)"
                :variables="variablesByTab[tabItem]"
              />
            </VContainer>
          </VCardText>
        </VCard>
      </VTabItem>
    </VTabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import expressions from '@/utils/data/expressions.json'
import { getMeasurementMapData, getEventsMapData } from '@/services/measurements'
import maths from '@/mixins/maths'
import vuexMixin from '@/mixins/vuexMixin'
import mapRemoveTooltipMixin from '@/mixins/map/mapRemoveTooltipMixin'

export default {
  name: 'MSVariablesDetails',

  components: {
    CMTable: () => import('@/components/Common/CMTable.vue'),
    MSMathExpressions: () => import('@/components/Measurements/MSMathExpressions.vue')
  },

  mixins: [maths, vuexMixin, mapRemoveTooltipMixin],

  data: () => ({
    slots: ['actions', 'plot', 'draw', 'heatmap'],
    cphase: null,
    expression: null,
    mathExpressions: [],
    mathOperator: null,
    events: [],
    groupPlots: [...Array(3).fill([])],
    heatmapValue: [],
    feedback: {},
    tabItems: ['measurements', 'events']
  }),

  computed: {
    ...mapState({
      timeSelection: ({ measurements }) => measurements.timeSelection,
      storedHeatmap: ({ measurements }) => measurements.heatmapValue,
      currentTab: ({ measurements }) => measurements?.currentTab?.title,
      plotData: ({ measurements }) => measurements.plotData
    }),

    tabs () {
      return [
        {
          ref: 'MeasurementsTab',
          disabled: this.currentTab !== 'Measurements',
          tag: 'M',
          langKey: 'measurements'
        },
        {
          ref: 'EventsTab',
          disabled: this.currentTab !== 'Events',
          tag: 'E',
          langKey: 'events'
        }
      ]
    },

    variables () {
      return this.plotData.map((item, index) => {
        const getPlotByIndex = plot => plot?.index === index

        const positionIndex = this.groupPlots.findIndex(position => position.find(getPlotByIndex))
        const currentPosition = this.groupPlots[positionIndex] || []
        const plotIndex = currentPosition.findIndex(getPlotByIndex)

        this.heatmapValue[index] =
          this.storedHeatmap[index] ||
          (this.storedHeatmap[index] !== undefined && this.storedHeatmap[index])

        return {
          actions: {
            type: 'CMBtnIcon',
            props: {
              icon: 'mdi-delete',
              small: false,
              callback: () => this.updatePlotData(index)
            },
            handleChange: () => {}
          },
          name: item.aggregation,
          plot: this.setPlotCol({
            item,
            index,
            positionIndex,
            plotIndex,
            currentPosition
          }),
          expression: expressions[index],
          heatmap: this.setHeatmapCol({ item, index })
        }
      })
    },

    variablesByTab () {
      return {
        events: this.variables.filter(v => v.plot.tab === 'events'),
        measurements: this.variables.filter(v => v.plot.tab === 'measurements')
      }
    },

    titleTranslations () {
      const titles = [
        { value: 'actions', text: 'grid_monitor.actions' },
        { value: 'name', text: 'name' },
        { value: 'plot', text: 'plot_one' },
        { value: 'draw', text: 'grid_monitor.draw' },
        { value: 'expression', text: 'grid_monitor.expression' },
        { value: 'heatmap', text: 'heatmap' }
      ]
      return titles
    }
  },

  watch: {
    heatmapValue: {
      handler (val) {
        this.setVuexElement({
          path: 'measurements.heatmapValue',
          value: val
        })
      }
    },
    feedback: {
      deep: true,
      handler (val) {
        if (val.visible) {
          this.hideAlert()
        }
      }
    },

    groupPlots: {
      handler (val) {
        this.setVuexElement({
          path: 'measurements.visiblePlots',
          value: val
        })
      }
    }
  },

  methods: {
    setPlotCol ({
      item = {},
      index,
      positionIndex,
      plotIndex,
      currentPosition = []
    }) {
      const removePrevPosition = () => {
        const filteredPosition = currentPosition.filter((_, i) => i !== plotIndex) || []
        const lengthChanged = filteredPosition.length !== (currentPosition.length || 0)

        Vue.set(this.groupPlots, positionIndex, filteredPosition)

        return lengthChanged
      }

      const setNewValue = payload => {
        const lengthChanged = removePrevPosition()

        Vue.set(this.groupPlots, payload, [
          ...this.groupPlots[payload], {
            ...this.plotData[index],
            position: payload,
            index,
            ...(lengthChanged && { visible: true })
          }
        ])
      }

      const items = this.groupPlots.map((_, i) => ({
        value: i,
        text: `Plot ${i + 1}`,
        disabled: i > 0 && (this.plotData.length < i + 1)
      }))

      return {
        tab: item.tab,
        type: 'VSelect',
        data: item.data,
        props: {
          items,
          value: positionIndex,
          placeholder: 'Select',
          dense: true,
          clearable: true,
          style: { width: '120px', margin: '0 auto' }
        },
        handleChange: payload => {
          payload === null
            ? removePrevPosition()
            : setNewValue(payload)

          return payload
        }
      }
    },

    setHeatmapCol ({
      index,
      item = {}
    }) {
      const getMapData = async item => {
        const commonProps = {
          timeStart: item.timeSelection.start,
          timeEnd: item.timeSelection.end
        }
        return this.currentTab === 'Measurements'
          ? await getMeasurementMapData({
            measurement: item.measurement,
            meters: item.selectedElementIds,
            aggregation: item.operator,
            ...commonProps
          })
          : await getEventsMapData({
            event: item.event,
            meters: item.meters,
            interval: item.interval,
            ...commonProps
          })
      }

      const updateStore = ({
        payload,
        item,
        response,
        maxValue,
        minValue,
        instantValue
      }) => {
        this.setVuexElement({
          path: `measurements.drawElementsOnMap.${this.currentTab}`,
          value: payload
            ? {
                units: item.units || '',
                elementIds: Object.keys(response).map(Number),
                data: response,
                maxRadiusValue: maxValue,
                minRadiusValue: minValue,
                interval: item.interval
              }
            : null
        })

        this.setVuexElement({
          path: 'instant',
          value: instantValue
        })
      }

      const setMaxValue = ({ response, maxValue, minValue = 0, payload }) => {
        if (!payload) return maxValue

        Object.entries(response).forEach(elementId => {
          if (elementId[1].value.length) {
            const elementMaxValue = Math.max(...elementId[1].value)
            if (elementMaxValue > maxValue) {
              maxValue = elementMaxValue
            }
            const elementMinValue = Math.min(...elementId[1].value)
            if (elementMinValue < minValue) {
              minValue = elementMinValue
            }
          }
        })

        const storedInstant = this.$store.state.measurements.drawElementsOnMap[this.currentTab]?.instant

        return {
          maxValue,
          minValue,
          instantValue: storedInstant || 0
        }
      }

      const handleResponseError = (response) => {
        console.log('response.error', response)

        this.feedback = {
          type: 'error',
          message: 'No data found with parameters entered',
          visible: true
        }

        this.heatmapValue = this.heatmapValue.map(() => false)
        return this.heatmapValue
      }

      return {
        'v-model': this.heatmapValue[index],
        type: 'VCheckbox',
        props: {
          disabled: item.origin !== 'backend' || item.selector !== 'grid.smart_meters',
          inputValue: this.heatmapValue[index],
          style: { display: 'inline-block' }
        },
        handleChange: async payload => {
          this.heatmapValue = this.heatmapValue.map((val, indexValue) => {
            if (this.plotData[indexValue].tab === this.currentTab.toLowerCase()) {
              return indexValue === index ? payload : false
            }
            return val
          })

          const DEFAULT_MAX_VALUE = 10

          const response = payload
            ? await getMapData(item)
            : []

          if (response === 500 || response === 404) {
            return handleResponseError(response)
          }

          const { maxValue, minValue, instantValue } = setMaxValue({
            response,
            payload,
            maxValue: DEFAULT_MAX_VALUE
          })

          if (payload) {
            this.addTooltipPane()
          } else {
            this.removeTooltipPane()
          }

          updateStore({
            payload,
            item,
            response,
            maxValue,
            minValue,
            instantValue: payload ? instantValue : undefined
          })
        }
      }
    },

    updatePlotData (removedIndex) {
      const updatedPlotData = this.plotData
        .filter((_, index) => index !== removedIndex)

      this.setVuexElement({
        path: 'measurements.plotData',
        value: updatedPlotData
      })

      this.groupPlots = this.groupPlots.map(group =>
        group
          .filter(data => data.index !== removedIndex)
          .map(fiteredData => ({
            ...fiteredData,
            ...(removedIndex < fiteredData.index && { index: fiteredData.index - 1 })
          }))
      )
    },

    removeAllVariables (tab) {
      this.setVuexElement({
        path: 'measurements.plotData',
        value: this.plotData.filter(plot => plot.tab !== tab)
      })
    },

    findVariablePlotable (tab) {
      return this.variablesByTab[tab]
        .some(variable => variable?.plot?.data?.value?.length)
    },

    hideAlert () {
      window.setInterval(() => {
        this.feedback = {}
      }, 6000)
    }
  }
}
</script>

<style scoped>
.plot-position {
  font-size: 1.5rem;
}

.scrollable {
  overflow: auto;
}

.mt-5 {
  margin-top: 5px;
}
</style>
